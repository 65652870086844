@import '@/styles/abstracts/variables';
@import '@/styles/abstracts/typography';
@import '@/styles/abstracts/placeholders';
@import '@/styles/abstracts/breakpoints';

.container {
  @extend %flex;

  padding: $spacing-lg 0 $spacing-sm;
  
  flex-direction: column;
  gap: $spacing-lg;
  order: 0;

  ul {
    @extend %flex;

    margin-top: 0.875rem;

    flex-direction: column;
    gap: $spacing-sm;
    
    list-style: none;

    li {
      border-radius: $radius-md;
      padding: $spacing-sm 0;

      background-color: $color-primary;

      a {
        @extend %flex;
        align-items: center;
        justify-content: center;
        gap: $spacing-md;

        span {
          color: #fff;
        }

        svg {
          width: 32px;
          height: auto;
  
          path {
            fill: #fff;
          }

          // Manual adjust due library icon inconsistency
          &[name="whatsapp"] {
            padding: .33rem;
          }
        }
      }
    }
  }

  @media only screen and (min-width: $breakpoint-lg) {
    padding: 0;
    
    ul {
      li {
        padding: 0;

        justify-content: flex-start;

        background-color: transparent;

        a {
          justify-content: flex-start;

          &:hover {
            text-decoration: underline;
          }

          span {
            color: $color-primary;
          }

          svg {
            path {
              fill: $color-neutral-800;
            }
          }
        }
      }
    }
  }
}

.title {
  text-transform: uppercase;

  font-size: $font-size-b2;
  font-weight: $font-weight-normal;
  line-height: $line-height-shorter;
}
@import '@/styles/abstracts/variables';
@import '@/styles/abstracts/placeholders';
@import '@/styles/abstracts/breakpoints';

.vdp-ui-accordion {
  &__header__custom {
    padding: 0;

    gap: $spacing-md;

    &:not(:first-of-type) {
      border-top: 1px solid $color-neutral-50;
    }
   
    &[aria-expanded=true] {
      background-color: transparent;
    }
  }

  &__title {
    padding: 1rem 0;

    font-size: $font-size-b1;
  }

  &__content__flex {
    @extend %flex;

    padding: 0 0 $spacing-md;

    flex-direction: column;
    gap: $spacing-md;
  }
  
  svg {
    path {
      fill: $color-primary;
    }
  }

  @media only screen and (min-width: $breakpoint-md) {
    &__content__flex {
      padding: $spacing-md 0rem;
    }
  }
}
// Colors
$color-primary-900: #1C4027;
$color-primary-800: #2B633D;
$color-primary-700: #3E945B;
$color-primary-600: #47A768;
$color-primary-500: #4FB672;
$color-primary-400: #69C187;
$color-primary-300: #83CD9C;
$color-primary-200: #A7DAB8;
$color-primary-100: #C9E8D3;
$color-primary-50: #E8F6ED;
$color-primary: $color-primary-800;

$color-neutral-900: #272727;
$color-neutral-800: #373737;
$color-neutral-700: #404040;
$color-neutral-600: #4a4949;
$color-neutral-500: #515050;
$color-neutral-400: #757575;
$color-neutral-300: #8a8a8a;
$color-neutral-200: #9e9e9e;
$color-neutral-100: #d6d6d6;
$color-neutral-50: #eeeeee;
$color-neutral-0: #ffffff;
$color-neutral: $color-neutral-800;

$color-green-900: #004500;
$color-green-800: #008300;
$color-green-700: #009400;
$color-green-600: #00a611;
$color-green-500: #17b51c;
$color-green-400: #4bc147;
$color-green-300: #70cc6b;
$color-green-200: #9cda97;
$color-green-100: #c3e8c0;
$color-green-50: #e6f6e5;
$color-green-25: #c2ff40;
$color-green: $color-green-900;

$color-yellow-900: #6b3103;
$color-yellow-800: #fc8f00;
$color-yellow-700: #fca000;
$color-yellow-600: #fcb300;
$color-yellow-500: #fcc100;
$color-yellow-400: #fcca26;
$color-yellow-300: #fcd54e;
$color-yellow-200: #fde081;
$color-yellow-100: #feecb3;
$color-yellow-50: #fef8e1;
$color-yellow: $color-yellow-900;

$color-red-900: #c7000d;
$color-red-800: #d51b1d;
$color-red-700: #e22525;
$color-red-600: #f4312b;
$color-red-500: #ff3c2a;
$color-red-400: #fd4f49;
$color-red-300: #f46e6e; 
$color-red-200: #fa9897;
$color-red-100: #ffcdd1;
$color-red-50: #ffebee;
$color-red: $color-red-900;

$color-text-primary: $color-neutral-800;
$color-text-secondary: $color-neutral-500;

$color-border: $color-neutral-100;

// Widths
$content-width: 74.75rem; // 1196px

// Border-Radius
$radius-sm: 0.25rem;  // 4px
$radius-md: 0.5rem;   // 8px
$radius-lg: 0.75rem;  // 12px
$radius-xl: 1rem;     // 16px
$radius-2xl: 1.5rem;  // 24px

// Spacing
$spacer: 1rem;        // 16px
$spacing-xs: 0.25rem; // 4px
$spacing-sm: 0.5rem;  // 8px
$spacing-md: 1rem;    // 16px
$spacing-lg: 1.5rem;  // 24px
$spacing-xl: 2rem;    // 32px
$spacing-2xl: 2.5rem; // 40px
$spacing-3xl: 3rem;   // 48px
$spacing-4xl: 3.5rem; // 56px
$spacing-5xl: 4rem;   // 64px
$spacing-6xl: 4.5rem; // 82px
$spacing-7xl: 5rem;   // 80px

@import '@/styles/abstracts/variables';
@import '@/styles/abstracts/typography';
@import '@/styles/abstracts/placeholders';
@import '@/styles/abstracts/breakpoints';

.container {
  @extend %flex;

  padding: $spacing-lg 0 0;

  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: $spacing-lg;  

  text-align: center;

  span {
    font-size: $font-size-s1;
    line-height: $line-height-base;
  }

  ul {
    @extend %flex;

    gap: $spacing-sm;
    list-style: none;

    li {
      @extend %flex;

      align-items: center;
    }
  }

  @media only screen and (min-width: $breakpoint-lg) {
    padding: $spacing-3xl 0 0;

    flex-direction: row;

    text-align: left;

    ul {
      gap: $spacing-md;
    }
  }
}
@import "@/styles/abstracts/variables";
@import "@/styles/abstracts/typography";
@import "@/styles/abstracts/placeholders";
@import "@/styles/abstracts/breakpoints";

.container {
  padding: $spacing-lg 0;
  position: relative;
  background-color: $color-primary;
}

.content {
  @extend %content;

  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  > nav,
  > ul {
    display: none;
  }

  ul {
    @extend %flex;

    align-items: center;
    gap: $spacing-2xl;

    color: $color-neutral-0;

    list-style: none;

    font-size: $font-size-b1;
    font-weight: $font-weight-normal;
    line-height: $line-height-tall;

    li {
      a {
        @extend %flex;

        align-items: center;
        gap: $spacing-sm;

        strong,
        span {
          line-height: $line-height-none;
        }

        svg {
          path {
            fill: $color-neutral-0;
          }
        }
      }
    }
  }

  button {
    border: none;
    background: transparent;

    svg {
      width: 2rem;
      height: 2rem;
      
      path {
        fill: $color-neutral-0;
      }
    }
  }

  @media only screen and (min-width: $breakpoint-lg) {
    > nav,
    > ul {
      display: initial;
    }

    ul {
      li {
        a {
          strong,
          span {
            color: $color-neutral-0;
          }
        }
      }
    }

    button {
      display: none;
    }
  }
}
@import './variables';
@import './typography';

%flex {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex; 
}

%content {
  @extend %flex;

  margin-right: auto;
  margin-left: auto;
  padding-right: $spacing-md;
  padding-left: $spacing-md;

  flex-direction: column;

  width: 100%;
  max-width: $content-width;
}

%button {
  @extend %flex;

  border: none;
  border-radius: $radius-xl;
  padding: $spacing-md;

  justify-content: center;
  align-items: center;

  width: 100%;

  color: #fff;
  background-color: $color-primary;

  transition: 300ms;

  font-size: $font-size-b2;
  font-weight: $font-weight-bold;
  line-height: $line-height-none;

  &:hover {
    background-color: $color-primary-500;
  }
}

%h2 {  
  font-size: $font-size-h2;
  font-weight: $font-weight-bold;
  line-height: $line-height-base;
}
@import '@/styles/abstracts/variables';
@import '@/styles/abstracts/typography';
@import '@/styles/abstracts/placeholders';
@import '@/styles/abstracts/breakpoints';

.container {
  min-height: 13.3rem;
  background-color: #E8E8E8;
  display: flex;
  align-items: center;
  padding: 2rem 0 2rem 0;
}

.content {
  @extend %content;
  
  justify-content: center;
  .inputs {
    display: flex;
    flex-direction: column;
    gap: $spacing-md;
    @media only screen and (min-width: $breakpoint-lg) {
      flex-direction: row;
    }
  }
}

.title {
  max-width: 18.75rem;
  font-size: $font-size-b1;
  font-weight: $font-weight-bold;
  color: #404040;
  margin-bottom: 1.3rem;
  font-style: normal;
  line-height: 24px;
  letter-spacing: 0.2px;

  strong {
    color: $color-primary;
  }

  @media only screen and (min-width: $breakpoint-lg) {
    max-width: unset;
    font-size: $font-size-h2;
  }
}

.button {
  height: fit-content;
  border: none;
  flex: 1;

  @media only screen and (min-width: $breakpoint-lg) {
    margin-top: 0;
  }
}

.input {
  background-color: #fff;
  
}

.inputContainerNewsName {
  flex: 1;
}

.inputContainerNewsEmail {
  flex: 1.5;
}
* {
  box-sizing: border-box;
}

body, blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

body {
  margin: 0;

  scroll-behavior: smooth;

  a {
    text-decoration: inherit;
    color: inherit;
  }
}

img, video {
  max-width: 100%;
  height: auto;
}

img, svg, video, canvas, audio, iframe, embed, object {
  display: block;
}

ol, ul {
  margin: 0;
  padding: 0;
}

button {
  font-family: inherit;
}
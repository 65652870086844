@import '@amopromo/vdp-ui/dist/css/Button.css';
@import '@amopromo/vdp-ui/dist/css/IconButton.css';
@import '@amopromo/vdp-ui/dist/css/DateRangePicker.css';
@import '@amopromo/vdp-ui/dist/css/Input.css';
@import '@amopromo/vdp-ui/dist/css/InputAutocomplete.css';
@import '@amopromo/vdp-ui/dist/css/InputDropdown.css';
@import '@amopromo/vdp-ui/dist/css/Select.css';
@import '@amopromo/vdp-ui/dist/css/Drawer.css';
@import '@amopromo/vdp-ui/dist/css/Accordion.css';

@import "@/styles/abstracts/variables";
@import "@/styles/abstracts/breakpoints";

@import '@/styles/accordion.scss';
@import '@/styles/inputDropdown.scss';

body {
  width: 100%;

  scroll-behavior: smooth;
}

h1, h2, h3, h4, h5, h6, p, span, strong, em {
  color: $color-text-primary;
}

@import '@/styles/abstracts/variables';

.container {
  list-style: none;

  li {
    & ~ li {
      margin-top: $spacing-sm;
    }
  }
}
@import '@/styles/abstracts/variables';
@import '@/styles/abstracts/typography';
@import '@/styles/abstracts/placeholders';
@import '@/styles/abstracts/breakpoints';

.container {
  @extend %flex;

  margin-top: $spacing-xl;
  border-bottom: 1px solid $color-primary;
  padding-bottom: $spacing-xl;

  flex-direction: column;
  justify-content: space-between;
  gap: $spacing-md;

  @media only screen and (min-width: $breakpoint-lg) {
    flex-direction: row;
  }
}

.title {
  text-transform: uppercase;

  font-weight: $font-weight-bold;
}

.methods {
  @extend %flex;

  flex-direction: column;
  justify-content: space-between;
  gap: $spacing-md;
  flex: 1;

  @media only screen and (min-width: $breakpoint-lg) {
    flex-direction: row;
  }

  @media only screen and (min-width: $breakpoint-xl) {
    gap: $spacing-2xl;
  }
}

.method {
  flex: 1;

  span {
    font-size: $font-size-b3;
  }
  
  div {
    @extend %flex;

    margin-top: $spacing-sm;

    flex-direction: row;
    align-items: center;
    gap: $spacing-sm;

    ul {
      @extend %flex;
  
      flex-wrap: wrap;
      gap: $spacing-sm;
  
      list-style: none;
    }
  }

  @media only screen and (min-width: $breakpoint-lg) {
    div {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  @media only screen and (min-width: $breakpoint-2xl) {
    flex: none;

    div {
      ul {
        flex-wrap: nowrap;
      }
    }
  } 
}
// Font-sizes
$font-size-h1: 1.75rem;   // 28px
$font-size-h2: 1.5rem;    // 24px
$font-size-h3: 1.375rem;  // 22px
$font-size-h4: 1.25rem;   // 20px
$font-size-special: 1.9rem; // 30px
 
$font-size-b1: 1.125rem;  // 18px
$font-size-b2: 1rem;      // 16px
$font-size-b3: 0.875rem;  // 14px

$font-size-s1: 0.75rem;   // 12px
$font-size-s2: 0.625rem;  // 10px

// Font-weights
$font-weight-normal: 400;
$font-weight-semibold: 600;
$font-weight-bold: 700;

// Line-heights
$line-height-normal: 'normal';
$line-height-none: 1;
$line-height-shorter: 1.25;
$line-height-short: 1.375;
$line-height-base: 1.5;
$line-height-tall: 1.625;
$line-height-taller: 2;
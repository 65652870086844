@import "@/styles/abstracts/variables";
@import "@/styles/abstracts/placeholders";
@import "@/styles/abstracts/typography";
@import "@/styles/abstracts/mixins";

.container {
  width: 100%;
  min-width: 16rem;

  ul {
    @extend %flex;

    flex-direction: column;

    list-style: none;

    li {
      a {
        @extend %flex;

        border-top: 1px solid $color-neutral-100;
        padding: $spacing-lg;
      
        align-items: center;
      
        svg {
          margin-right: .75rem;
        }
      
        span,
        strong {
          font-size: $font-size-b2;
        }
      
        span {
          font-weight: $font-weight-normal;
        }
      
        strong {
          font-weight: $font-weight-semibold;
        }
      }
    }
  }
}
@import '@/styles/abstracts/variables';
@import '@/styles/abstracts/typography';
@import '@/styles/abstracts/placeholders';
@import '@/styles/abstracts/breakpoints';

.container {
  border-top: 4px solid;
  border-image: linear-gradient(
    to right,
    #64ba00 0%,
    #64ba00 25%,
    #68629c 25%,
    #68629c 50%,
    #ffd012 50%,
    #ffd012 75%,
    #007e47 75%,
    #007e47 100%
  );
  border-image-slice: 1;
  padding: $spacing-2xl 0;
}

.content {
  @extend %content;
}

.header {
  @extend %flex;

  border-bottom: 1px solid $color-primary;
  padding-bottom: $spacing-xl;

  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: $spacing-xl;

  > div {
    @extend %flex;

    align-items: center;
    gap: $spacing-md;

    span {
      color: $color-neutral-500;

      font-size: $font-size-b3;
      font-weight: $font-weight-normal;
    }
  }

  @media only screen and (min-width: $breakpoint-lg) {
    flex-direction: row;
    justify-content: space-between;
  }
}

.body {
  @extend %flex;

  flex-direction: column;

  @media only screen and (min-width: $breakpoint-lg) {
    padding-top: $spacing-xl;

    flex-direction: row;
    gap: $spacing-xl;
  }

  @media only screen and (min-width: $breakpoint-xl) {
    gap: $spacing-5xl;
  }
}

.accordion {
  margin-top: $spacing-md;
  border-bottom: 1px solid $color-primary;

  order: 1;

  > button {
    border-top: 1px solid $color-primary;
    border-bottom: none;
  }
}

.menus {
  @extend %flex;

  justify-content: space-between;
  flex-direction: column;
  flex: 1;

  @media only screen and (min-width: $breakpoint-lg) {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: $spacing-xl;
    grid-row-gap: 0px;
  }

  @media only screen and (min-width: $breakpoint-xl) {
    grid-column-gap: $spacing-5xl;
  }
}

.menu {
  display: flex;
  flex-direction: column;
  gap: $spacing-sm;
  
  &.grid-area-1 { grid-area: 1 / 5 / 2 / 2; }
  &.grid-area-2 { grid-area: 1 / 3 / 2 / 3; }
  &.grid-area-3 { grid-area: 1 / 4 / 2 / 5; }
  &.grid-area-4 { grid-area: 1 / 4 / 2 / 5; margin-top: 5rem;}
  &.grid-area-5 { grid-area: 1 / 1 / 2 / 6; }

  ul {
    @extend %flex;

    flex-direction: column;

    list-style: none;

    li {
      a {
        font-size: $font-size-b3;
        font-weight: $font-weight-normal;
        line-height: $line-height-base;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}

.menuTitle {
  text-transform: uppercase;

  font-size: $font-size-b2;
  font-weight: $font-weight-normal;
  line-height: $line-height-normal;
}